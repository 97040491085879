import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/hegel/hegel/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "unknown-type"
    }}>{`Unknown Type`}</h1>
    <hr></hr>
    <p>{`Sometimes you don't know which type will come as function argument/variable value/object property value.
As example `}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/ru/docs/Web/JavaScript/Reference/Global_Objects/JSON/parse"
      }}><inlineCode parentName="a">{`JSON.parse`}</inlineCode></a>{`. This function can return array of any types, object with any properties or any valid JSON value like string, number, boolean and etc.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`const anything = JSON.parse('{ "name": null }');
`}</code></pre>
    <p>{`The most general type in Hegel is `}<inlineCode parentName="p">{`unknown`}</inlineCode>{`. You can imagine this type as `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Universal_set"
      }}>{`"Universal Set"`}</a>{`. It means that all possible values in JavaScript has `}<inlineCode parentName="p">{`unknown`}</inlineCode>{` type. It's a supertype for all types in Hegel type system.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`const anyNumber: unknown = 2;
const anyString: unknown = "something";
const anything: unknown = class User {};
`}</code></pre>
    <blockquote>
      <p parentName="blockquote">{`If you already familiar with TypeScript or Flow.js, this type has the same semantic as `}<inlineCode parentName="p">{`unknown`}</inlineCode>{` in TS or `}<inlineCode parentName="p">{`mixed`}</inlineCode>{` in Flow.js`}</p>
    </blockquote>
    <p>{`But, as you can understand, you can assign concrete type to wider type, but you can't assign wider type to a concrete type.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`const concreteType: string = "I'm good for unknown";
const wideType: unknown = "I'm too wide for string";

const iCanBeUnknown: unknown = concreteType;
const andICanBeString: string = concreteType;

const andICanBeUnknownToo: unknown = wideType;
const butCantBeString: string = wideType;
`}</code></pre>
    <p>{`Also, it means that you can't use unknown type as an argument for function which is waiting for some concrete type:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`function waitingFor(value: string) {
  return \`Awesome \${value}\`;
}

const anything: unknown = 4;

// Error: Type "unknown" is incompatible with type "string"
const result = waitingFor(anything);
`}</code></pre>
    <p>{`Or operator:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`function waitingFor(value: unknown) {
  // Error: Type "unknown" is incompatible with type "string"
  return \`Awesome \${value}\`;
}

const result = waitingFor(class User {});
`}</code></pre>
    <blockquote>
      <p parentName="blockquote">{`Actually, Hegel provides ability to `}<a parentName="p" {...{
          "href": "/docs/type-refinement"
        }}>{`refine type`}</a>{`.`}</p>
    </blockquote>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      